@tailwind base;
@tailwind components;
@tailwind utilities;
*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    font-family: 'Poppins';
}

body {
    counter-reset: section;
}

.info-card-column {
    @apply relative;
    min-height: 250px;
}

.card {
    opacity: 0;
    display: flex;
    visibility: hidden;
    transition: 
        opacity 1s,
        visibility 1s;
    @apply absolute;
}

.card-active,
.expanded {
    visibility: visible;
    opacity: 1;
}

.card-title {
    position: relative;
    @apply text-distsalmon;
}

.card-number
 {
    position: absolute;
    left: -2.25rem;
    top: -0.2rem;
    width: 2.25rem;
    text-align: center;
    font-size: 1.75rem;
}

/* .card-active .card-title {
    @apply text-distsalmon;
} */

.card-detail {
    max-height: 0;
    opacity: 0;
    transition: 
        max-height 0.5s ease-in-out,
        opacity 0.5s;
}

.expanded ~ .card-detail {
    /* max-height: 235px; */
    max-height: 1000px;
    opacity: 1;
}

.webgl {
    height: 50vh;
    max-width: 100%!important;
    box-shadow: 0px 20px 20px 0px #14202c;
}